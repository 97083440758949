<template>
  <div>
    <!-- table -->
    <data-table
      :columns="columns"
      requesturl="/barcodes"
      :server-params-modifier="serverParamsModifier"
    >

      <!-- Column: Action -->
      <template #actions="{ rowData }">
        <span>
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              :disabled="rowData.kitPickupStatus !== 'Received'"
              @click="selectedBarcode = rowData._id; selectedBarcodeType = rowData.kitType; $refs.changeStatusRef.show();duBarcode = rowData.duBarcode; saBarcode = rowData.saBarcode; stBarcode= rowData.stBarcode; corBarcode = rowData.corBarcode "
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Edit Status</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="rowData.kitType==='kid'"
              @click="$router.push(`/barcode/kid/${rowData._id}/report/upload`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>Upload Report</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="rowData.kitType==='kid'"
              :disabled="(rowData.status !== 'Data analysis completed' && rowData.status !== 'Reports Ready') || rowData.questionnaireStatus !== 'Survey Completed'"
              @click="$router.push(`/barcode/kid/${rowData._id}/report/view`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>View & Publish Reports</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="rowData.kitType==='adult'"
              @click="$router.push(`/barcode/adult/${rowData._id}/report/upload`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>Upload Report</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="rowData.kitType==='adult'"
              :disabled="(rowData.status !== 'Data analysis completed' && rowData.status !== 'Reports Ready') || rowData.questionnaireStatus !== 'Survey Completed'"
              @click="$router.push(`/barcode/adult/${rowData._id}/report/view`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>View & Publish Reports</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="rowData.kitType==='longevity'"
              @click="$router.push(`/barcode/longevity/${rowData._id}/report/upload`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>Upload Report</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="rowData.kitType==='longevity'"
              :disabled="(rowData.status !== 'Data analysis completed' && rowData.status !== 'Reports Ready') || rowData.questionnaireStatus !== 'Survey Completed'"
              @click="$router.push(`/barcode/longevity/${rowData._id}/report/view`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>View & Publish Reports</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="rowData.kitType==='covid'"
              @click="$router.push(`/barcode/covid/${rowData._id}/report/upload`)"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-50"
              />
              <span>Upload Covid Reports</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="rowData.kitType==='covid'"
              :disabled="(rowData.status !== 'Data analysis completed' && rowData.status !== 'Reports Ready') || rowData.questionnaireStatus !== 'Survey Completed'"
              @click="$router.push(`/barcode/covid/${rowData._id}/report/view`)"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              />
              <span>View & Publish Covid Reports</span>
            </b-dropdown-item>
            <!-- <b-dropdown-item
                v-if="rowData.isObsolete!==true"
                @click="obsoleteKitId = rowData._id;obseleteKitTypeID = (rowData.kitType==='covid')?rowData.corBarcode:rowData.duBarcode; $refs.setObsolete.show()"
              >
                <feather-icon
                  icon="XSquareIcon"
                  class="mr-50"
                />
                <span>Obsolete Kit</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="obsoleteKitId = rowData._id;obseleteKitTypeID = (rowData.kitType==='covid')?rowData.corBarcode:rowData.duBarcode; $refs.setUnObsolete.show()"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="mr-50"
                />
                <span>Un-obsolete Kit</span>
              </b-dropdown-item> -->
          </b-dropdown>
        </span>
      </template>

    </data-table>
    <b-modal
      ref="changeStatusRef"
      title="Change Status"
      @hide="selectedStatus = null; selectedBarcode = null; selectedBarcodeType = null; statusChangedAt = null"
      @cancel="selectedStatus = null; selectedBarcode = null; selectedBarcodeType = null; statusChangedAt = null"
      @ok="changeStatus"
    >
      <!-- TODO: format the ids -->
      <div
        v-if="corBarcode"
        class="py-1"
      >
        <b>COR Barcode:</b> {{ corBarcode }} <br>
      </div>
      <div
        v-if="duBarcode"
        class="py-1"
      >
        <b>DU Barcode:</b> {{ duBarcode }} <br>
      </div>
      <div
        v-if="stBarcode"
        class="py-1"
      >
        <b>ST Barcode:</b> {{ stBarcode }} <br>
      </div>
      <div
        v-if="saBarcode"
        class="py-1"
      >
        <b>SA Barcode:</b> {{ saBarcode }}
      </div>
      <b-form-select
        v-model="selectedStatus"
        class="mb-1"
        :options="statusOptions[selectedBarcodeType]"
      />

      <b-form-datepicker
        id="date"
        v-model="statusChangedAt"
        :value-as-date="true"
        class="mb-1 mt-2"
        :max="maxDate"
        placeholder="When was this completed?"
      />

    </b-modal>
    <!-- <b-modal
      ref="setObsolete"
      ok-variant="danger"
      title="Set kit as obsolete"
      ok-title="Yes"
      @ok="obsoleteKit"
    >
      <p>
        Are you sure you want to make the kit obsolete?
      </p>
    </b-modal>
    <b-modal
      ref="setUnObsolete"
      ok-variant="danger"
      title="Set kit as unobsolete"
      ok-title="Yes"
      @ok="unObsoleteKit"
    >
      <p>
        Are you sure you want to make the kit unobsolete?
      </p>
    </b-modal> -->
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import DataTable from '@/components/DataTable.vue'
import moment from 'moment'

export default {
  components: {
    DataTable,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    return {
      obsoleteKitId: null,
      obseleteKitTypeID: null,
      columns: [
        {
          label: 'DU Barcode',
          field: 'duBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'ST Barcode',
          field: 'stBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'SA Barcode',
          field: 'saBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'COR Barcode',
          field: 'corBarcode',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Previous Barcodes',
          field: 'previousBarcodes',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
          formatFn: previousBarcodes => previousBarcodes.join(' <br>'),
        },
        {
          label: 'isFollowUp',
          field: 'followUp',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
          formatFn: followUp => (followUp === true ? 'yes' : 'no'),
        },
        {
          label: 'Account ID',
          field: 'accountID',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Region',
          field: 'country',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Referred By',
          field: 'referredBy',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit User',
          field: 'kitUser',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Barcode Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit Pickup Status',
          field: 'kitPickupStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Questionnaire Status',
          field: 'questionnaireStatus',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
        },
        {
          label: 'Kit type',
          field: 'kitType',
          filterOptions: {
            enabled: true,
            placeholder: 'Search',
          },
          formatFn: kitType => kitType.replace(/^\w/, c => c.toUpperCase()),
        },
        {
          label: 'Reports',
          field: 'reports',
        },
        // {
        //   label: 'Obsolete Status',
        //   field: 'isObsolete',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Search',
        //     filterDropdownItems: [{ value: true, text: 'Obsolete' }, { value: false, text: 'Unobsolete' }],
        //   },
        //   formatFn: (data, filterString) => (data === filterString),
        // },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      serverParams: {
        columnFilters: {
          kitUser: { $ne: '' }, isObsolete: false,
        },
        page: 1,
        perPage: 20,
      },
      selectedBarcode: null,
      selectedBarcodeType: null,
      selectedStatus: null,
      statusChangedAt: null,
      maxDate: today,
      duBarcode: null,
      saBarcode: null,
      stBarcode: null,
      corBarcode: null,
      statusOptions: {
        kid: [
          {
            text: 'Select an option',
            value: null,
          },
          {
            text: 'Sequencing prep completed',
            value: 'Sequencing prep completed',
          },
          {
            text: 'Sequencing completed',
            value: 'Sequencing completed',
          },
          {
            text: 'Data analysis completed',
            value: 'Data analysis completed',
          },
        ],
        adult: [
          {
            text: 'Select an option',
            value: null,
          },
          {
            text: 'Sequencing prep completed',
            value: 'Sequencing prep completed',
          },
          {
            text: 'Sequencing completed',
            value: 'Sequencing completed',
          },
          {
            text: 'Data analysis completed',
            value: 'Data analysis completed',
          },
        ],
        longevity: [
          {
            text: 'Select an option',
            value: null,
          },
          {
            text: 'Sequencing prep completed',
            value: 'Sequencing prep completed',
          },
          {
            text: 'Sequencing completed',
            value: 'Sequencing completed',
          },
          {
            text: 'Data analysis completed',
            value: 'Data analysis completed',
          },
        ],
        covid: [
          {
            text: 'Select an option',
            value: null,
          },
          {
            text: 'Sequencing completed',
            value: 'Sequencing completed',
          },
          {
            text: 'Data analysis completed',
            value: 'Data analysis completed',
          },
        ],
      },
    }
  },
  methods: {
    localTimestampToUtcTimestamp(timestamp) {
      return moment(moment.unix(timestamp).toDate()).utc(true).format('X')
    },
    changeStatus(bvModalEvent) {
      if (this.selectedStatus === null || this.statusChangedAt === null) {
        this.$bvToast.toast('All fields are required', {
          title: 'Incomplete details',
          variant: 'primary',
          solid: true,
        })
        return
      }
      bvModalEvent.preventDefault()
      const dateObj = new Date(this.statusChangedAt)
      const timestamp = dateObj.getTime()
      this.$http.patch(`barcodes/${this.selectedBarcodeType}`, {
        _id: this.selectedBarcode,
        status: this.selectedStatus,
        statusChangedAt: parseInt(this.localTimestampToUtcTimestamp(timestamp) / 1000, 10),
      }).then(() => {
        this.$bvToast.toast('Barcode status changed Successfully!', {
          title: 'Task Complete',
          variant: 'success',
          solid: true,
        })
        // this.getTableData()
      }).catch(this.handleError)
        .finally(() => {
          // this.selectedBarcode = null
          // this.selectedBarcodeType = null
          // this.selectedStatus = null
          this.closeLoading()
          this.$refs.changeStatusRef.hide()
        })
    },

    serverParamsModifier(serverParams) {
      const serverParamsClone = JSON.parse(JSON.stringify(serverParams))
      serverParamsClone.columnFilters.kitUser = { $ne: '' }
      serverParamsClone.columnFilters.isObsolete = false
      return serverParamsClone
    },
  },
  // obsoleteKit() {
  //   this.$http.patch(`barcode/${this.obsoleteKitId}/obsolete`).then(() => {
  //     this.$bvToast.toast(`${this.obseleteKitTypeID} has been made obsolete`, {
  //       title: 'Success!',
  //       variant: 'success',
  //       autoHideDelay: 5000,
  //       solid: true,
  //     })
  //     this.getTableData()
  //   }).catch(error => { this.handleError(error) })
  // },
  // unObsoleteKit() {
  //   this.$http.patch(`barcode/${this.obsoleteKitId}/unObsolete`).then(() => {
  //     this.$bvToast.toast(`${this.obseleteKitTypeID} has been made unobsolete`, {
  //       title: 'Success!',
  //       variant: 'success',
  //       autoHideDelay: 5000,
  //       solid: true,
  //     })
  //     this.getTableData()
  //   }).catch(error => { this.handleError(error) })
  // },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
